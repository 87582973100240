export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'user', label: "User", _style:'min-width:100px' },
    { key: 'product', label: "Product", _style:'min-width:100px' },
    { key: 'payment', label: "No Payment", _style:'min-width:100px' },
    { key: 'full_name', label: "Nama", _style:'min-width:100px' },
    { key: 'nik', label: "NIK", _style:'min-width:100px' },
    { key: 'work', label: "Pekerjaan", _style:'min-width:100px' },
    { key: 'address', label: "Alamat", _style:'min-width:100px' },
    { key: 'sim_type', label: "SIM", _style:'min-width:100px' },
    { key: 'needs', label: "Keperluan", _style:'min-width:100px' },
    { key: 'total_point', label: "Point", _style:'min-width:30px' },
    { key: 'status', label: "Status", _style:'min-width:100px' },
    { key: 'expired_at', label: "Tanggal Expired", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
